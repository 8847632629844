<template>
  <div class="lecturesWrapper">
    <div class="container">
      <slot name="default"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "LecturesWrapper"
}
</script>