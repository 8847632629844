<template>
  <BaseButton
      v-if="!loggedIn"
      :is-link="true"
      :href="registrationLink"
  >
    <slot name="notLogged">Registrovať</slot>
  </BaseButton>
  <BaseButton
      v-else
      :is-link="true"
      :href="link"
  >
    <slot name="logged">Chcem študovať</slot>
  </BaseButton>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import {mapGetters} from "vuex";

export default {
  props: {
    link: {
      type: String,
      default: '/courses'
    },
    registrationLink: {
      type: String,
      default: '/registration'
    },
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
  components: {BaseButton},
}
</script>