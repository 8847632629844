<template>
  <div class="jobsList">
    <div class="jobsList__header">
      <div class="container container--xs">
        <h2>Možnosti pracovného uplatnenia</h2>
        <slot name="headerText">
          <p>
            Vieš, že akonáhle absolvuješ aspoň niekoľko prvých kurzov ITides.sk, môžeš <strong>získať novú prácu u Microsoft partnerov?</strong> Snažíme sa tak vykryť dramatický nedostatok IT špecialistov, ktorých v tejto chvíli na Slovensku chýba až 15 000! <strong>Aké pozície sa ti konkrétne ponúkajú,</strong> ak absolvuješ kurz 365 Microsoft?
          </p>
        </slot>
      </div>
    </div>

    <div v-if="numberOfItems" class="jobsList__body">
      <ul
          class="jobsList__body-ul"
          :class="`jobsList__body-ul--${numberOfItems}`"
      >
        <slot/>
      </ul>
    </div>

    <div v-if="footerEnabled" class="jobsList__footer container container--sm">
      <slot name="footer">
        <ActionButton :link="link"/>
      </slot>
    </div>
  </div>
</template>
<script>
import ActionButton from "@/components/ActionButton.vue";

export default {
  props: {
    numberOfItems: {
      type: Number,
      required: true
    },
    link: {
      type: String,
      default: '/courses/uvodny-kurz'
    },
    footerEnabled: {
      type: Boolean,
      default: true
    },
  },
  components: {ActionButton},
}
</script>