<template>
  <div class="indexReviewCarousel">
    <div class="indexReviewCarousel__wrapper">
      <div
          v-for="item in data"
          :key="item"
      >
        <IndexReviewCarouselItem
            :name="item.name"
            :image="item.image"
            :text="item.text"
        />
      </div>
    </div>
  </div>
</template>
<script>
import IndexReviewCarouselItem from "@/components/IndexReviewCarouselItem";
import {tns} from "tiny-slider";

export default {
  data() {
    return {
      carousel: null,
      data: [
        {
          name: 'Denisa Gafová',
          image: 'denisa-gafova.jpg',
          text: '„Vo voľnom čase som pozeral prednášky na ITjede zadarmo, ako mi to vyhovovalo. Kariérni poradcovia z ITjede sledovali môj akademický pokrok. Na základe toho ma oslovili s veľmi zaujímavou pracovnou ponukou. Som veľmi šťastný, že vďaka tomu budem môcť robiť to, čo ma baví. Robiť ekonómiu alebo účtovníctvo by ma z dlhodobého hľadiska určite nenapĺňalo.“',
        },
        {
          name: 'Jan Svoboda',
          image: 'jan-svoboda.jpg',
          text: '„Páčilo sa mi, ako sa lektori podelili o svoje know-how a skúsenosti absolútne bez váhania. Povedali vám všetko tak, ako to je. Ak si udržíte svoju vášeň, IT konzultanti vás radi spoja s firmami. Pre mňa je táto cesta opäť dôkazom, že ak vytrváte, môžete urobiť čokoľvek.“ ',
        },
        {
          name: 'Karel Antoš',
          image: 'karel-antos.jpg',
          text: 'Vďaka ITide.sk sa mi podarilo získať pozíciu v jednej z popredných IT spoločností na trhu. Pomerne rýchlo som získal povedomie o MS Dynamics 365 a využití technológií v praxi, čo bolo pre mňa zásadné, pretože som predtým pôsobil v inom odbore.',
        },
        {
          name: 'Lada Kosová',
          image: 'lada-kosova.jpg',
          text: 'Často som počúvala, aký je IT lukratívny odbor. Hovorila som si, že by som do toho mohla ísť aj ja. Celkom ma vystrašili ceny klasických kurzov a zároveň som nemala na štúdium veľa času. Dopočula som sa o ITide.sk a skúsila som to. Baví ma na tom, že môžem študovať kedykoľvek, odkiaľkoľvek a vlastným tempom.',
        },
      ]
    }
  },
  mounted() {
    this.createCarousel();
  },
  methods: {
    createCarousel() {
      this.carousel = tns({
        container: '.indexReviewCarousel__wrapper',
        edgePadding: this.itemWidth,
        items: 1,
        gutter: 0,
        slideBy: 1,
        useLocalStorage: false,
        autoplay: false,
        autoplayHoverPause: true,
        autoplayTimeout: 3500,
        mouseDrag: true,
        center: true,
        controls: false,
        navPosition: 'bottom',
        responsive: {
          0: {
            edgePadding: 50,
            gutter: 30,
          },
          576: {
            edgePadding: 100,
            gutter: 70,
          },
          768: {
            edgePadding: 140,
          },
          992: {
            edgePadding: 300,
            gutter: 120,
          },
          1200: {
            edgePadding: 400,
          },
          1400: {
            edgePadding: 500,
          },
          1600: {
            edgePadding: 600,
          },
          1920: {
            edgePadding: this.countLargeWidth,
          }
        }
      });
    },
    destroyCarousel() {
      this.carousel.destroy();
    }
  },
  computed: {
    countLargeWidth() {
      return window.top.innerWidth * 35 / 100
    }
  },
  unmounted() {
    this.destroyCarousel();
  },
  components: {
    IndexReviewCarouselItem
  }
}
</script>